@import "~bootstrap/scss/bootstrap.scss";

* {
  border-radius: 0 !important;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.nav {
  .nav-link {
    &.active {
      color: $dark !important;
      background-color: $body-bg !important;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 6px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.react-datepicker-wrapper,
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}
